/***** Header *****/

.header {
  background: #fff;
  box-shadow: 0 3px 10px rgba(#000, 0.12);
  height: 69px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  @media (max-width: 575px) {
    height: 60px;
  }
  .header-i {
    display: flex;
    align-items: center;
    min-height: 69px;
    @media (max-width: 575px) {
      min-height: 60px;
    }
  }
  .logo {
    line-height: 0;
    align-self: flex-start;
    margin-right: 20px;
    flex: 0 0 151px;
    @media (max-width: 575px) {
      flex: 0 0 120px;
    }
    a {
      display: block;
      line-height: 0;
    }
    img {
    }
  }
  .header-nav {
    display: flex;
    align-items: center;
    margin-left: auto;
    @media (max-width: 1023px) {
      position: absolute;
      top: 69px;
      left: 0;
      width: 100%;
      height: 100vh;
      background: #fff;
      opacity: 0;
      visibility: hidden;
      z-index: 10;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 69px;
    }
  }
  .header-menu {
    ul {
      display: flex;
      align-items: stretch;
      margin: 0;
      padding: 0;
      list-style: none;
      gap: 10px;
      @media (max-width: 1023px) {
        flex-direction: column;
        margin-bottom: 20px;
      }
      li {
        margin: 0;
        a,
        span {
          position: relative;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
          padding: 22px 25px;
          line-height: 25px;
          display: block;
          text-align: center;
          @media (max-width: 1399px) {
            padding: 22px 15px;
            font-size: 16px;
          }
          @media (max-width: 1279px) {
            padding: 22px 10px;
          }
          @media (max-width: 1023px) {
            padding: 10px 0;
          }
          @media (max-width: 399px) {
            padding: 5px 0;
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8px;
            background: transparent;
            z-index: 1;
            transition: all 0.2s;
            @media (max-width: 1023px) {
              display: none;
            }
          }
        }
        &.active {
          a,
          span {
            &:after {
              background: $green;
            }
          }
        }
        &:not(.active) {
          a:hover {
            @media (max-width: 1023px) {
              color: $primaryhover;
            }
            &:after {
              background: $green;
            }
          }
        }
      }
    }
  }
  .header-lang {
    margin: 0 -25px 0 10px;
    @media (max-width: 1023px) {
      margin: 0;
    }
    a {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 22px 25px;
      line-height: 25px;
      @media (max-width: 1399px) {
        padding: 22px 15px;
        font-size: 16px;
      }
      color: $green;
      &:hover {
        color: $greenhover;
      }
    }
  }
  .toggler {
    width: 40px;
    height: 40px;
    display: none;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    @include max($lg) {
      display: flex;
    }
    span {
      width: 24px;
      height: 3px;
      display: block;
      position: relative;
      background: $green;
      transition: all 0.2s;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background: $green;
        transition: all 0.2s;
      }
      &:before {
        top: -7px;
      }
      &:after {
        top: 7px;
      }
    }
    &:hover {
      span {
        background: $greenhover;
        &:before,
        &:after {
          background: $greenhover;
        }
      }
    }
  }
}

body.is-mobilemenu {
  overflow-y: hidden;
  .header {
    .header-nav {
      opacity: 1;
      visibility: visible;
    }
  }
}
