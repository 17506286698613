/***** Homepage *****/

.section-hero {
  background: url("../images/bnr-hero.jpg") 50% 50% no-repeat;
  background-size: cover;
  padding: 285px 0 52px;
  color: #fff;
  @media (max-width: 1399px) {
    padding: 200px 0 40px;
  }
  @media (max-width: 1023px) {
    padding: 150px 0 30px;
  }
  @media (max-width: 575px) {
    padding: 120px 0 30px;
  }
  h1 {
    margin-bottom: 42px;
    @media (max-width: 1399px) {
      margin-bottom: 30px;
    }
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }
  .info {
    font-size: 24px;
    font-weight: 600;
    max-width: 50%;
    margin-bottom: 90px;
    @media (max-width: 1399px) {
      font-size: 22px;
    }
    @media (max-width: 1023px) {
      font-size: 20px;
    }
  }
  .details {
    display: flex;
    align-items: flex-start;
    padding-right: 75px;
    justify-content: space-between;
    @media (max-width: 1399px) {
      padding-right: 30px;
    }
    @media (max-width: 1023px) {
      padding-right: 0;
      gap: 30px;
    }
    @media (max-width: 575px) {
      flex-wrap: wrap;
    }
    .item {
      width: 207px;
      flex: 0 0 207px;
      text-align: right;
      @media (max-width: 1399px) {
        width: calc(25% - 23px);
        flex: 0 0 calc(25% - 23px);
      }
      @media (max-width: 575px) {
        width: calc(50% - 15px);
        flex: 0 0 calc(50% - 15px);
      }
      .item-num {
        color: $green;
        font-size: 40px;
        line-height: 1;
        background: url("../images/dotline-green.svg") 0 100% no-repeat;
        background-size: auto 9px;
        padding-bottom: 10px;
        margin-bottom: 12px;
        width: 207px;
        @media (max-width: 1399px) {
          width: 100%;
          background-position: 100% 100%;
          font-size: 32px;
          margin-bottom: 10px;
        }
        @media (max-width: 1023px) {
          background-size: 100% auto;
          font-size: 27px;
          margin-bottom: 8px;
        }
      }
      .item-desc {
        font-size: 19px;
        @media (max-width: 1399px) {
          font-size: 18px;
        }
        @media (max-width: 1023px) {
          font-size: 16px;
        }
      }
    }
  }
}

.section-project {
  padding: 100px 0 77px;
  @media (max-width: 1399px) {
    padding: 80px 0 50px;
  }
  @media (max-width: 1023px) {
    padding: 60px 0 40px;
  }
  h2 {
  }
  .subtitle {
    margin-bottom: 35px;
    @media (max-width: 1399px) {
      margin-bottom: 25px;
    }
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }
  .desc {
    font-size: 18px;
    font-weight: 600;
    p {
    }
  }
}

.section-location {
  .location-i {
    padding: 82px 75px 52px;
    background: #f2f2f2 url("../images/bnr-location.jpg") 100% 0 no-repeat;
    background-size: cover;
    box-shadow: 0 4px 10px rgba(#000, 0.2);
    position: relative;
    @media (max-width: 1399px) {
      padding: 40px;
    }
    @media (max-width: 1023px) {
      padding: 40px 300px 40px 20px;
    }
    @media (max-width: 575px) {
      padding: 30px 15px;
    }
    &:after {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#fff, 0.7);
      z-index: 0;
      @media (max-width: 575px) {
        display: block;
      }
    }
    > * {
      position: relative;
      z-index: 1;
    }
  }
  h2 {
  }
  .subtitle {
    margin-bottom: 40px;
    @media (max-width: 1399px) {
      margin-bottom: 25px;
    }
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }
  .desc {
    p {
    }
  }
}

.section-about {
  padding: 90px 0 400px;
  background: url("../images/bnr-windmills.png") 0 100% no-repeat;
  background-size: 100% auto;
  @media (max-width: 1399px) {
    padding: 70px 0 350px;
  }
  @media (max-width: 1023px) {
    padding: 60px 0 50vw;
  }
  .about-i {
  }
  h2 {
  }
  .desc {
    p {
    }
  }
  .img {
    display: block;
    line-height: 0;
    @media (max-width: 1023px) {
      margin: 30px 0 0;
    }
    @media (max-width: 575px) {
      margin: 30px -15px 0;
    }
    img {
    }
  }
}

.section-benefits {
  background: $primary;
  color: #fff;
  padding: 30px 0 120px;
  @media (max-width: 1399px) {
    padding: 20px 0 80px;
  }
  @media (max-width: 1023px) {
    padding: 20px 0 60px;
  }
  h2 {
    background: none;
    color: #fff;
    margin-bottom: 52px;
    @media (max-width: 1399px) {
      margin-bottom: 36px;
    }
    @media (max-width: 1023px) {
      margin-bottom: 32px;
    }
    &:after {
      background: #fff;
    }
  }
  .benefit-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      gap: 30px;
    }
    .item {
      .item-title {
        font-size: 26px;
        color: $yellow;
        margin-bottom: 18px;
        line-height: 1.2;
        @media (max-width: 1399px) {
          font-size: 22px;
          margin: 0 0 15px;
        }
        @media (max-width: 1023px) {
          font-size: 20px;
          margin: 0 0 12px;
        }
      }
      .item-desc {
      }
    }
  }
}

.section-dev {
  padding: 92px 0 135px;
  @media (max-width: 1399px) {
    padding: 60px 0 80px;
  }
  @media (max-width: 1023px) {
    padding: 40px 0 60px;
  }
  h2 {
    margin-bottom: 20px;
  }
  .dev-plan {
    display: flex;
    align-items: flex-end;
    gap: 0 9px;
    font-size: 19px;
    @media (max-width: 1399px) {
      font-size: 16px;
    }
    @media (max-width: 1023px) {
      font-size: 18px;
      display: block;
    }
    .year {
      flex-basis: calc(16.6666% - 9px);
      @media (max-width: 1023px) {
        display: flex;
        flex-direction: column;
      }
      .year-steps {
        margin-bottom: 5px;
        @media (max-width: 1023px) {
          order: 2;
        }
        .step {
          margin-bottom: 18px;
          min-height: 110px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: stretch;
          @media (max-width: 1023px) {
            min-height: 0;
            margin: 15px 0 0;
          }
          .step-txt {
            font-weight: 600;
            padding-bottom: 15px;
            background: url("../images/dotline-dark.svg") 0 100% no-repeat;
            background-size: auto 9px;
            text-align: right;
            line-height: 1.2;
            margin-bottom: 3px;
            @media (max-width: 1023px) {
              background-position: 100% 100%;
            }
          }
          .step-date {
            color: $green;
            text-align: right;
            font-weight: 600;
            line-height: 1.2;
          }
        }
      }
      .year-b {
        border-top: 9px solid $green;
        padding-top: 10px;
        font-weight: bold;
        color: $green;
        @media (max-width: 1023px) {
          order: 1;
          border-top: none;
          border-bottom: 4px solid $green;
          padding: 0 0 10px;
        }
      }
      &.year-past {
        .year-b {
          border-top-color: $primary;
        }
      }
    }
  }
}

.section-gallery {
  background: $primary;
  color: #fff;
  padding: 80px 0 130px;
  @media (max-width: 1399px) {
    padding: 60px 0 80px;
  }
  @media (max-width: 1023px) {
    padding: 40px 0 60px;
  }
  h2 {
    background: none;
    color: #fff;
    margin-bottom: 60px;
    @media (max-width: 1399px) {
      margin-bottom: 36px;
    }
    @media (max-width: 1023px) {
      margin-bottom: 32px;
    }
  }
  .gallery-block {
    @media (max-width: 575px) {
      margin: 0 -15px;
    }
  }
  .gallery-swiper {
    position: relative;
    overflow: hidden;
    z-index: 1;
    .swiper-slide {
      .slide-img {
        position: relative;
        padding-bottom: 63.334%;
        line-height: 0;
        // margin-bottom: 25px;
        margin: 100px 0 125px;
        transition: all 0.3s;
        transform: scale(130%);
        @media (max-width: 1023px) {
          margin: 0;
          transform: none;
          margin-bottom: 10px;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .slide-count {
        text-align: left;
        font-weight: bold;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        line-height: 1.2;
        @media (max-width: 575px) {
          padding: 0 15px;
        }
        span {
          color: $green;
        }
      }
      .slide-desc {
        text-align: left;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s;
        line-height: 1.2;
        @media (max-width: 575px) {
          padding: 0 15px;
        }
      }
      &.swiper-slide-active {
        z-index: 10;
        .slide-img {
          transform: scale(173%);
          @media (max-width: 1023px) {
            transform: none;
          }
        }
        .slide-count {
          opacity: 1;
          visibility: visible;
        }
        .slide-desc {
          opacity: 1;
          visibility: visible;
        }
      }
      &.swiper-slide-visible:not(.swiper-slide-active) {
        z-index: 1;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 34px;
      height: 65px;
      top: calc(50% - 58px);
      @media (max-width: 1023px) {
        width: 24px;
        height: 44px;
        top: calc(50% - 25px);
      }
      &:after {
        color: $yellow;
        font-size: 65px;
        @media (max-width: 1023px) {
          font-size: 44px;
        }
      }
    }
  }
}

.section-contacts {
  padding: 100px 0 200px;
  background: $primary;
  color: #fff;
  @media (max-width: 1399px) {
    padding: 60px 0 80px;
  }
  @media (max-width: 1023px) {
    padding: 40px 0 60px;
  }
  h2 {
    background: none;
    color: #fff;
    margin-bottom: 50px;
    @media (max-width: 1399px) {
      margin-bottom: 36px;
    }
    @media (max-width: 1023px) {
      margin-bottom: 32px;
    }
  }
  .col-lg-6:not(:first-child) {
    @media (max-width: 1023px) {
      margin-top: 30px;
    }
  }
  h3 {
    font-weight: 500;
  }
  p {
    margin-bottom: 0.5em;
    line-height: 1.2;
    a {
      color: #fff;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
