/***** Footer *****/

.footer {
  background: url("../images/bnr-bottom.jpg") 50% 100% no-repeat;
  background-size: cover;
  text-align: right;
  padding: 720px 0 30px;
  @media (max-width: 1023px) {
    padding: 600px 0 15px;
  }
  p {
    margin: 0;
    line-height: 1.2;
  }
}
