/***** Layout *****/

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1340px;
  @media (max-width: 1279px) {
    padding: 0 20px;
  }
  @media (max-width: 575px) {
    padding: 0 15px;
  }
}

.main {
  padding-top: 69px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
  [class*="col-"] {
    padding: 0 20px;
    flex: 0 0 100%;
  }
  .col-lg-6 {
    @include min($lg) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
