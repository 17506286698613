/* Color */
$primary: #005e78;
$primarydark: #005167;
$primaryhover: #077c9c;
$green: #00c0ab;
$greenhover: #19e1ca;
$yellow: #ffbb00;

$font-heading: "Raleway", sans-serif;
$font-basic: "Raleway", sans-serif;

/* Size */
$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
