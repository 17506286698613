/***** Buttons *****/

.btn {
  cursor: pointer;
  transition: all 0.2s;
  display: inline-block;
  text-align: center;
  padding: 10px 20px;
  &:hover {

  }
  &.btn-primary {
    &:hover {
    }
  }
  &.btn-secondary {
    &:hover {
    }
  }
}
.btns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -4px;
  .btn {
    margin: 0 8px 4px 0;
    &:last-child {
      margin-right: 0;
    }
  }
  &.btns-centered {
    justify-content: center;
  }
}